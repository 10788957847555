import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDc2HN9edujYXBB0GCffgriMxQhPr3sIWE",
  authDomain: "porta-8211c.firebaseapp.com",
  databaseURL: "https://porta-8211c.firebaseio.com",
  projectId: "porta-8211c",
  storageBucket: "porta-8211c.appspot.com",
  messagingSenderId: "525020750850"
};
firebase.initializeApp(config);
let db = firebase.firestore()

export default db